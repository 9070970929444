import {IFolder, IFolderMove, IGroupedFolder} from "../../../../models/folders";
import {ICourseForAddInFedkurs, ICourses} from "../../../../models/courses";
import React, {useEffect, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import Portal from "../portal/Portal";
import {AxiosError} from "axios";
import {CoursesService} from "../../../../services/courses.service";
import Modal from "../Modal";
import {ReactComponent as CloseIcon} from "../../../../assets/images/global/close-modal-icon.svg";
import {useMutation, useQuery} from "react-query";
import ErrorMessage from "../../error/ErrorMessage";
import Loader from "../../loader/Loader";
import {FolderService} from "../../../../services/folder.service";
import Select, {SingleValue} from "react-select";
import {Link} from "react-router-dom";
import {ReactComponent as CheckboxUnchecked} from "../../../../assets/images/tests/checkbox-unchecked.svg";
import {ReactComponent as CheckboxChecked} from "../../../../assets/images/tests/checkbox-checked.svg";

interface AddFolder {
    onClose: () => void
    folderId?: string
    parentFolder?: IFolder
    setModalCourse: (course: ICourses) => void
}

const ModalAddCourse = (
    {
        onClose,
        folderId,
        parentFolder,
        setModalCourse
    }: AddFolder
) => {

    const [error, setError] = useState<number | null>(null)
    const [nameParentFolder, setNameParentFolder] = useState<string>("")
    const [activeFolder, setActiveFolder] = useState<number | null>(null)
    const [activeCourse, setActiveCourse] = useState<number | null>(null)
    const [groupFolder, setGroupFolder] = useState<IGroupedFolder[]>([])
    const [optionsCourse, setOptionsCourse] = useState<ICourseForAddInFedkurs[]>([])

    const {
        setValue,
        register,
        handleSubmit,
        formState: {errors}
    } = useForm<ICourses>()

    useEffect(() => {
        setValue("folderId", Number(folderId))
        if (parentFolder) {
            setNameParentFolder(parentFolder.name)
        }
    }, [])

    const addCourse = useMutation(
        (data: ICourses) => CoursesService.create(data),
        {
            onSuccess: (response) => {
                setModalCourse(response.data)
                onClose()
            },
            onError: (error) => {
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const onSubmit: SubmitHandler<ICourses> = async (data) => {
        addCourse.mutate(data)
    }


    const listFolders = useQuery(
        ["list_folders_add_course"],
        () => FolderService.getList(),
        {
            enabled: window.location.host.indexOf("fedkurs") !== -1,
            onSuccess: (response) => {
                response.forEach(element => {
                    let first = element[0]
                    let options: IFolderMove[] = []
                    element.map(el => {
                        options.push(
                            {
                                id: Number(el.id),
                                name: el.name
                            }
                        )
                    })
                    setGroupFolder(prev => [...prev, {
                        label: first?.name,
                        options: options
                    }])
                })
            }
        }
    )

    const handleChangeFolder = (newValue: SingleValue<IFolderMove>) => {
        if (newValue) {
            setActiveFolder(newValue.id)
        }
    }

    const listCourses = useQuery(
        ["list_courses_add_course", activeFolder],
        () => CoursesService.getAllForFedkurs(Number(activeFolder)),
        {
            enabled: !!activeFolder && window.location.host.indexOf("fedkurs") !== -1,
            onSuccess: (response) => {
                setOptionsCourse([])
                response.forEach(element => {
                    setOptionsCourse(prev => [...prev, {
                        id: element.id,
                        name: element.name
                    }])
                })
            }
        }
    )

    const handleChangeCourse = (newValue: SingleValue<ICourseForAddInFedkurs>) => {
        if (newValue) {
            setActiveCourse(newValue.id)
        }
    }

    // if (listFolders.isLoading) return <Loader/>
    // if (listFolders.isError) {
    //     const e = listFolders.error as AxiosError
    //     setError(e.response ? e.response.status : 400)
    // }
    //
    // if (listCourses.isLoading) return <Loader/>
    // if (listCourses.isError) {
    //     const e = listCourses.error as AxiosError
    //     setError(e.response ? e.response.status : 400)
    // }

    return (
        <Portal>
            <Modal>
                {addCourse.isLoading && <Loader/>}
                {error && <ErrorMessage numberError={error}/>}
                {window.location.host.indexOf("fedkurs") !== -1 ? (
                    <div className="flex flex-col gap-[24px] text-[16px]">
                        <div className="flex justify-between items-center font-bold text-[16px]">
                            Создание нового курса для Федкурса на основе другого курса
                            <CloseIcon className="cursor-pointer" onClick={onClose}/>
                        </div>
                        <Select
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 5,
                                colors: {
                                    ...theme.colors,
                                    primary: "#17AB57",
                                    primary25: "#17AB571a"
                                }
                            })}
                            placeholder={"Выберите папку"}
                            options={groupFolder}
                            onChange={handleChangeFolder}
                            isSearchable={true}
                            noOptionsMessage={() => "Не создано папок"}
                            getOptionLabel={(folder: IFolderMove) => folder.name}
                            getOptionValue={(folder: IFolderMove) => String(folder.id)}
                        />
                        {listCourses.data && activeFolder && (
                            <Select
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    colors: {
                                        ...theme.colors,
                                        primary: "#17AB57",
                                        primary25: "#17AB571a"
                                    }
                                })}
                                placeholder={"Выберите курс"}
                                options={optionsCourse}
                                onChange={handleChangeCourse}
                                isSearchable={true}
                                getOptionLabel={(course: ICourseForAddInFedkurs) => course.name}
                                getOptionValue={(course: ICourseForAddInFedkurs) => String(course.id)}
                            />
                        )}
                        <div className="flex gap-[12px] justify-end">
                            {activeCourse && (
                                <Link
                                    to={`/add-course-in-fedkurs/${activeCourse}`}
                                    className="text-white font-medium py-[8px] px-[16px] rounded-[5px] bg-[#17AB57]"
                                >
                                    Создать
                                </Link>
                            )}
                            <button
                                className="font-medium py-2 px-4 rounded-[5px] border-[1px] border-solid border-[#17AB57]"
                                onClick={onClose}>Отмена
                            </button>
                        </div>
                    </div>
                ) : (
                    <form className="flex flex-col gap-[24px] text-[16px]" onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex justify-between items-center font-bold text-[16px]">
                            Создание нового курса
                            <CloseIcon className="cursor-pointer" onClick={onClose}/>
                        </div>
                        <div>
                            <input
                                className="border-[1px] border-solid border-[#BDBDBD] font-medium py-[8px] pl-[10px] pr-[12px] focus:outline-none focus:border-[#17AB57] w-full rounded-[5px]"
                                type="text"
                                placeholder="Введите название курса"
                                {...register("name", {required: true})}/>
                            {errors.name && errors.name.type === "required" && (
                                <p className="text-red-600">Название обязательно</p>
                            )}
                            <input type="hidden" {...register("folderId")}/>
                        </div>
                        {parentFolder && Object.keys(parentFolder).length > 0 && (
                            <>
                                <div>
                                    <input
                                        className="border-[1px] border-solid border-[#BDBDBD] font-medium py-[8px] pl-[10px] pr-[12px] focus:outline-none focus:border-[#17AB57] w-full rounded-[5px]"
                                        type="text"
                                        placeholder="Введите базовое образование"
                                        {...register("basicEducation", {required: true})}/>
                                    {errors.basicEducation && errors.basicEducation.type === "required" && (
                                        <p className="text-red-600">Базовое образование обязательно</p>
                                    )}
                                </div>
                                {nameParentFolder === "ПП" && (
                                    <>
                                        <div>
                                            <input
                                                className="border-[1px] border-solid border-[#BDBDBD] font-medium py-[8px] pl-[10px] pr-[12px] focus:outline-none focus:border-[#17AB57] w-full rounded-[5px]"
                                                type="text"
                                                placeholder="Квалификация на базе ВО профильная"
                                                {...register("qualification", {required: true})}/>
                                            {errors.qualification && errors.qualification.type === "required" && (
                                                <p className="text-red-600">Квалификация на базе ВО профильная обязательна</p>
                                            )}
                                        </div>
                                        <div>
                                            <input
                                                className="border-[1px] border-solid border-[#BDBDBD] font-medium py-[8px] pl-[10px] pr-[12px] focus:outline-none focus:border-[#17AB57] w-full rounded-[5px]"
                                                type="text"
                                                placeholder="Квалификация на базе ВО непрофильная"
                                                {...register("qualificationNonCore", {required: true})}/>
                                            {errors.qualificationNonCore && errors.qualificationNonCore.type === "required" && (
                                                <p className="text-red-600">Квалификация на базе ВО профильная
                                                    обязательна</p>
                                            )}
                                        </div>
                                        <div>
                                            <input
                                                className="border-[1px] border-solid border-[#BDBDBD] font-medium py-[8px] pl-[10px] pr-[12px] focus:outline-none focus:border-[#17AB57] w-full rounded-[5px]"
                                                type="text"
                                                placeholder="Квалификация на базе СПО профильная"
                                                {...register("qualificationSPO", {required: true})}/>
                                            {errors.qualificationSPO && errors.qualificationSPO.type === "required" && (
                                                <p className="text-red-600">Квалификация на базе СПО профильная обязательна</p>
                                            )}
                                        </div>
                                        <div>
                                            <input
                                                className="border-[1px] border-solid border-[#BDBDBD] font-medium py-[8px] pl-[10px] pr-[12px] focus:outline-none focus:border-[#17AB57] w-full rounded-[5px]"
                                                type="text"
                                                placeholder="Квалификация на базе СПО непрофильная"
                                                {...register("qualificationSPONonCore", {required: true})}/>
                                            {errors.qualificationSPONonCore && errors.qualificationSPONonCore.type === "required" && (
                                                <p className="text-red-600">Квалификация на базе СПО непрофильная обязательна</p>
                                            )}
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                        <label className="flex items-center gap-[6px] cursor-pointer w-full">
                            <input className="hidden peer" type="checkbox" {...register("isMain")}/>
                            <CheckboxUnchecked className="block peer-checked:hidden flex-shrink-0"/>
                            <CheckboxChecked className="hidden peer-checked:block flex-shrink-0"/>
                            Основной курс
                        </label>
                        <div className="flex gap-[12px] justify-end">
                            <button
                                type="submit"
                                className="text-white font-medium py-[8px] px-[16px] rounded-[5px] bg-[#17AB57]"
                            >
                                Создать
                            </button>
                            <button
                                className="font-medium py-2 px-4 rounded-[5px] border-[1px] border-solid border-[#17AB57]"
                                onClick={onClose}>Отмена
                            </button>
                        </div>
                    </form>
                )}

            </Modal>
        </Portal>
    )
}

export default ModalAddCourse